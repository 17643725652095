<template>
  <div :style="`border: 1px inset ${getColorEstado}`">
    <b-card class="m-0">
      <p>Afiliado</p>
      <b-row>
        <b-col md="6">
          <b-form-group
            label-for="input-horizontal"
            label="C.U.I.L."
          >
            <h5>{{ detalleSolicitudFormDto.solicitanteCuil }}</h5>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label-for="input-horizontal"
            label="Nombre"
          >
            <h5>{{ detalleSolicitudFormDto.usuario.apellido }}, {{ detalleSolicitudFormDto.usuario.nombre }}</h5>
          </b-form-group>
        </b-col>
      </b-row>
      <p>Beneficiario</p>
      <b-row>
        <b-col md="4">
          <b-form-group
            label-for="input-horizontal"
            label="DNI"
          >
            <h5><strong>{{ detalleSolicitudFormDto.beneficiarioDni }}</strong></h5>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label-for="input-horizontal"
            label="Nombre"
          >
            <h5><strong>{{ detalleSolicitudFormDto.beneficiarioNombre }}</strong></h5>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label-for="input-horizontal"
            label="Fecha Nacimiento"
          >
            <h5><strong> {{ formatearFecha(detalleSolicitudFormDto.beneficiarioFechaNac) }} </strong></h5>
          </b-form-group>
        </b-col>
      </b-row>
      <p>Beneficio</p>
      <b-row>
        <b-col md="4">
          <b-form-group
            label-for="input-horizontal"
            label="Quincho"
          >
            <h5><strong>{{ detalleSolicitudFormDto.beneficio.nombre }}</strong></h5>
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group
            label-for="input-horizontal"
            label="Estado"
          >
            <h4 :style="`color:${getColorEstado}`">
              {{ estado }}
            </h4>
          </b-form-group>
        </b-col>
        <b-col
          v-if="detalleSolicitudFormDto.motivoRechazo !== null"
          md="6"
        >
          <b-form-group label="Motivo rechazo">
            <quill-editor
              v-if="detalleSolicitudFormDto.motivoRechazo !== null"
              id="txtComentario"
              v-model="detalleSolicitudFormDto.motivoRechazo"
              theme="snow"
              disabled
              :options="editorOption"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <p>Detalle</p>
      <b-row>
        <b-col md="3">
          <b-form-group
            label-for="input-horizontal"
            label="Fecha de reserva"
          >
            <h5><strong> {{ formatearFecha(detalleSolicitudFormDto.solquincho.fechaReserva) }}</strong></h5>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-for="input-horizontal"
            label="Teléfono contacto"
          >
            <h5><strong>{{ detalleSolicitudFormDto.solquincho.telContacto }}</strong></h5>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-for="input-horizontal"
            label="Cantidad de personas"
          >
            <h5><strong> {{ detalleSolicitudFormDto.solquincho.cantPersonas }} </strong></h5>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-for="input-horizontal"
            label="Horario"
          >
            <h5><strong> {{ detalleSolicitudFormDto.solquincho.horario }} </strong></h5>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="12"
          class="px-2"
        >
          <b-card-actions
            class="p-0"
            title="Observaciones"
            action-collapse
          >
            <b-form-textarea
              id="textAreaRequisitos"
              v-model="detalleSolicitudFormDto.solquincho.observaciones"
              no-auto-shrink
              placeholder="Observaciones"
              rows="6"
              disabled
            />
          </b-card-actions>
        </b-col>
      </b-row>
    </b-card>
    <hr v-if="showBtnRechazar || showBtnAprobar">
    <b-row
      v-if="showBtnRechazar || showBtnAprobar"
      class="justify-content-md-center mt-1"
    >
      <b-col md="2">
        <b-button
          v-if="showBtnRechazar"
          size="sm"
          squared
          variant="danger"
          :disabled="disabledBtnRechazar"
          @click="rechazarSolicitud()"
        >
          Rechazar
          <span
            v-if="detalleSolicitudFormDto.isDoing.rechazandoSolicitud"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
        </b-button>
      </b-col>
      <b-col md="2">
        <!--variant="success"-->
        <b-button
          v-if="showBtnAprobar"
          size="sm"
          squared
          variant="success"
          :disabled="disabledBtnAprobar"
          @click="aprobarSolicitud()"
        >
          Aprobar
          <span
            v-if="detalleSolicitudFormDto.isDoing.aprobandoSolicitud"
            class="spinner-border spinner-border-sm"
            aria-hidden="true"
          />
        </b-button>

      </b-col>
    </b-row>
    <br>
    <div v-if="showComentarioModal">
      <comentario-modal
        :show-comentario-modal="showComentarioModal"
        @contentComentarioModal="contentComentarioModal"
      />
    </div>
  </div>
</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { mapGetters } from 'vuex'
import { quillEditor } from 'vue-quill-editor'
import { consultaSolicitudResource } from '@/services/consultaSolicitudResource'
import useAppConfig from '@core/app-config/useAppConfig'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ComentarioModal from './ComentarioModal.vue'

export default {
  components: {
    ComentarioModal,
    quillEditor,
    BCardActions,
  },
  props: {
    detalleSolicitud: {
      type: Object,
      required: true,
      default: undefined,
    },
  },
  data() {
    return {
      isEditComentarioModal: false,
      isWorkingAprobar: false,
      editorOption: {
        modules: {
          toolbar: false,
        },
        placeholder: '',
        theme: 'snow',
        readOnly: 'true',
      },
      swallConfig: {
        background: 'linear-gradient(325deg, #000000 0%, #2a90cb 100%)',
        textColor: 'white',
      },
      overlayConfig: {
        rounded: 'sm',
        variant: 'transparent',
        opacity: '0.5',
        blur: '5px',
        message: 'Buscando lista de adjuntos',
      },
      comentarioDto: {
        comentario: null,
      },
      showComentarioModal: false,
      detalleSolicitudFormDto: {},
      nameModulo: 'CONSULTA_BENEFICIO',
    }
  },
  computed: {
    estado() {
      return this.detalleSolicitudFormDto.solicitudEstado.nombreCustom
    },
    quienRetira() {
      let retira = ''
      if (this.detalleSolicitudFormDto.solconprod.retiraUsuario) {
        retira = 'AFILIADO'
      } else {
        retira = 'Otra persona'
      }
      return retira
    },
    isGestor() {
      let isGestor = false
      const someGestor = this.currentUser.roles.filter(rol => rol.codigo === 'GESTOR_BENEFICIOS')
      if (someGestor.length > 0) {
        isGestor = true
      }
      return isGestor
    },
    getColorEstado() {
      const { skin } = useAppConfig()
      let whiteBlack = 'black'
      if (skin.value === 'dark') {
        whiteBlack = 'white'
      }
      let color = whiteBlack
      if (this.detalleSolicitud.solicitudEstado.codigo === '003') {
        // Rechazada
        color = '#FC1C1C'
      }
      if (this.detalleSolicitud.solicitudEstado.codigo === '001') {
        // Aprobada
        color = '#53D135'
      }
      if (this.detalleSolicitud.solicitudEstado.codigo === '004') {
        // Cancelada
        color = '#FFA07A'
      }
      if (this.detalleSolicitud.solicitudEstado.codigo === '005') {
        // Etregada
        color = '#58D3F7'
      }
      return color
    },
    disabledBtnAprobar() {
      return this.detalleSolicitudFormDto.isDoing.rechazandoSolicitud || this.detalleSolicitudFormDto.isDoing.aprobandoSolicitud
    },
    disabledBtnRechazar() {
      return this.detalleSolicitudFormDto.isDoing.rechazandoSolicitud || this.detalleSolicitudFormDto.isDoing.aprobandoSolicitud
    },
    showBtnAprobar() {
      const cumpleCondicionEstado = this.detalleSolicitudFormDto.solicitudEstado.nombre === 'PENDIENTE'
      return this.isGestor && cumpleCondicionEstado
    },
    showBtnRechazar() {
      const cumpleCondicionEstado = this.detalleSolicitudFormDto.solicitudEstado.nombre === 'PENDIENTE'

      return this.isGestor && cumpleCondicionEstado
    },
    ...mapGetters(
      {
        currentUser: 'getCurrentUser',
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
      },
    ),
  },
  watch: {
    detalleSolicitud: {
      deep: true,
      handler() {
        this.detalleSolicitudFormDto = this.detalleSolicitud
      },
    },
  },
  created() {
    this.detalleSolicitudFormDto = { ...this.detalleSolicitud }
  },
  methods: {
    setSwallColor() {
      const { skin } = useAppConfig()
      if (skin.value === 'dark') {
        // this.swallConfig.background = 'linear-gradient(325deg, #000000 0%, #2a90cb 100%)'
        this.swallConfig.background = '#1A2F50'

        this.swallConfig.textColor = 'white'
      } else {
        // this.swallConfig.background = 'linear-gradient(325deg, #ffffff 0%, #ffffff 100%)'
        this.swallConfig.background = '#FFFFFF)'
        this.swallConfig.textColor = 'black'
      }
    },
    changethis() {
      this.showComentarioModal = false
      return null
    },
    formatearFecha(fechaPara) {
      let fecha = '-'
      if (fecha !== null) {
        fecha = this.formatDateTable(fechaPara, 'DD/MM/YYYY')
      }
      return fecha
    },
    async contentComentarioModal({ comentarioDto }) {
      await this.changethis()
      if (comentarioDto !== null) {
        this.detalleSolicitudFormDto.isDoing.rechazandoSolicitud = true
        this.comentarioDto = { ...comentarioDto }
        this.updateEstadoSaveComentario('003')
      } else {
        this.comentarioDto.comentario = null
      }
    },
    rechazarSolicitud() {
      this.showComentarioModal = true
    },
    aprobarSolicitud() {
      this.detalleSolicitudFormDto.isDoing.aprobandoSolicitud = true
      this.updateEstado('001')
    },
    updateEstado(codigoEstado) {
      const idSolicitud = this.detalleSolicitudFormDto.id
      consultaSolicitudResource().updateSolicitudEstado(idSolicitud, codigoEstado, this.detalleSolicitudFormDto.solicitudEstado).then(result => {
        this.detalleSolicitudFormDto = { ...result }
        this.$emit('changeItemIndex', { detalleSolicitud: this.detalleSolicitudFormDto })
      }).catch(error => {
        console.error('error', error)
      })
        .finally(() => {
          this.detalleSolicitudFormDto.isDoing.aprobandoSolicitud = false
        })
    },
    updateEstadoSaveComentario(codigoEstado) {
      const errDetail = 'No es posible enviar el correo de notificación, deberá comunicarle la novedad al afiliado por otro medio'
      const idSolicitud = this.detalleSolicitudFormDto.id
      consultaSolicitudResource().updateSolicitudEstadoSaveComentario(idSolicitud, codigoEstado, this.comentarioDto, this.detalleSolicitudFormDto.solicitudEstado).then(result => {
        this.detalleSolicitudFormDto = { ...result }
        consultaSolicitudResource().notificarRechazo(result.id).then(() => {
        }).catch(error => {
          console.error('error:', error)
          this.showMessageBig('Envío de email', 'error', `${errDetail} <br> ${error.data.error}<hr>`)
        })
        this.$emit('changeItemIndex', { detalleSolicitud: this.detalleSolicitudFormDto })
      }).catch(error => {
        console.error('error', error)
      })
        .finally(() => {
          this.detalleSolicitudFormDto.isDoing.rechazandoSolicitud = false
          this.showComentarioModal = false
        })
    },
    showMessageBig(titleMessage, icon_, htmlErrors) {
      this.setSwallColor()
      const htmlText = `<p style="color:${this.swallConfig.textColor}">${htmlErrors}</p>`
      this.$swal({
        allowOutsideClick: false,
        title: titleMessage,
        html: htmlText,
        icon: icon_,
        // showCancelButton: true,
        confirmButtonText: 'Cerrar',
        background: this.swallConfig.background,
        // cancelButtonText: 'No',

        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          //
        }
      })
    },
  },
}
</script>
