<template>
  <div>
    <validation-observer ref="formValidationConsultaBeneficio">
      <b-form @submit.prevent="onSubmit">
        <b-card>
          <b-row>
            <b-col md="12">
              <h4>Solicitudes de beneficios</h4>
            </b-col>
          </b-row>
          <br>
          <b-row>
            <b-col
              v-if="!isAfiliadoOnly"
              md="2"
            >
              <b-form-group
                id="frmCuil"
                label="C.U.I.L."
              >
                <validation-provider
                  name="Cuil"
                  :rules="{ regex: /^[+]?([0-9]+)$/ }"
                >
                  <div slot-scope="{ errors, failedRules }">
                    <b-form-input
                      id="txtFiltroCuil"
                      ref="inputFilter"
                      v-model="consultaBeneSolicitudFiltroDto.solicitanteCuil"
                      v-b-tooltip.hover="'Ingrese el C.U.I.L. o parte del mismo'"
                      :state="errors.length > 0 ? false:null"
                      autocomplete="off"
                      placeholder=" C.U.I.L."
                      :disabled="isBusyBuscando"
                    />
                    <span
                      v-if="failedRules.regex"
                      class="text-danger"
                    >Solo números positivos.</span>
                  </div>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                id="frmBeneficio"
                label="Beneficio"
              >
                <b-form-select
                  id="selectBeneficio"
                  v-model="consultaBeneSolicitudFiltroDto.beneficioId"
                  v-b-tooltip.hover="'Seleccione el beneficio'"
                  :options="optionsBeneficio"
                  value-field="id"
                  text-field="nombre"
                  rounded
                  :disabled="isBusyBuscandoListaBeneficios || isBusyBuscando"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                id="frmEstado"
                label="Estado"
              >
                <b-form-select
                  id="selectBeneficioEstado"
                  v-model="consultaBeneSolicitudFiltroDto.solicitudEstadoId"
                  v-b-tooltip.hover="'Seleccione el estado'"
                  :options="optionsEstado"
                  value-field="id"
                  text-field="nombre"
                  rounded
                  :disabled="isBusyBuscandoEstados || isBusyBuscando"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                id="frmDesde"
                label="Desde"
              >
                <validation-provider
                  name="valProvFechaDesde"
                  :rules="consultaBeneSolicitudFiltroDto.fechaHastaComponent!==null ? 'required' : ''"
                >
                  <div slot-scope="{ errors, failedRules }">
                    <b-form-datepicker
                      id="sltDesde"
                      v-model="consultaBeneSolicitudFiltroDto.fechaDesdeComponent"
                      v-b-tooltip.hover="'Fecha de solicitud'"
                      locale="es-AR"
                      :max="maxDate"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      label-help="Use las teclas de flecha para navegar por el calendario"
                      label-current-month="Mes actual"
                      label-next-month="Mes siguiente"
                      label-next-year="Año siguiente"
                      label-no-date-selected="desde"
                      label-prev-month="Mes anterior"
                      label-prev-year="Año anterior"
                      :state="errors.length > 0 ? false : null"
                      :disabled="isBusyBuscando"
                      @input="onFechaDesdeChange()"
                    />
                    <small
                      v-if="failedRules.required"
                      class="text-danger"
                    >Campo obligatorio</small>
                  </div>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                id="frmHasta"
                label="Hasta"
              >
                <validation-provider
                  name="valProvFechaDesde"
                  :rules="consultaBeneSolicitudFiltroDto.fechaDesdeComponent!==null ? 'required' : ''"
                >
                  <div slot-scope="{ errors, failedRules }">
                    <b-form-datepicker
                      id="sltHasta"
                      v-model="consultaBeneSolicitudFiltroDto.fechaHastaComponent"
                      v-b-tooltip.hover="'Fecha de solicitud'"
                      :min="consultaBeneSolicitudFiltroDto.fechaDesdeComponent"
                      :max="maxDate"
                      locale="es-AR"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      label-help="Use las teclas de flecha para navegar por el calendario"
                      label-current-month="Mes actual"
                      label-next-month="Mes siguiente"
                      label-next-year="Año siguiente"
                      label-no-date-selected="hasta"
                      label-prev-month="Mes anterior"
                      label-prev-year="Año anterior"
                      :disabled="consultaBeneSolicitudFiltroDto.fechaDesdeComponent === null ? true: false || isBusyBuscando"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small
                      v-if="failedRules.required"
                      class="text-danger"
                    >Campo obligatorio</small>
                  </div>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <div class="mycontainer">
                <b-button
                  id="btnClearSearchBeneficio"
                  v-b-tooltip.hover="'Limpiar filtros'"
                  squared
                  size="sm"
                  class="closeStyle"
                  :disabled="isBusyBuscando"
                  @click="()=>clearSearchConsultaSolicitudes()"
                >

                  <font-awesome-icon icon="fa-solid fa-eraser" />
                </b-button>
                <b-button
                  id="btnSearchBeneficio"
                  v-b-tooltip.hover
                  title="Buscar solicitudes"
                  variant="primary"
                  squared
                  :disabled="isBusyBuscando"
                  @click="validationFormUsuario()"
                >
                  <feather-icon icon="SearchIcon" />
                </b-button>
                <b-button
                  v-if="showBtnExportarExcelConsutaSolicitud"
                  id="btnDownloadReporteExcel"
                  v-b-tooltip.hover
                  squared
                  size="sm"
                  :disabled="isBusyReporteExcel"
                  title="Descargar consulta solicitudes en formato excel, con los filtros de búsqueda actuales"
                  variant="primary"
                  @click="()=>downloadReporteExcel()"
                >
                  <font-awesome-icon
                    v-if="!isBusyReporteExcel"
                    icon="fa-solid fa-file-export"
                  />
                  <span
                    v-if="isBusyReporteExcel"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                </b-button>
              </div>

            </b-col>

          </b-row>
          <br>
          <div>
            <b-row>
              <b-col md="12">
                <!--sticky-header="70vh  :no-border-collapse="true""
responsive
        @row-dblclicked="dobleClik"-->
                <b-table
                  id="tblBeneficio"
                  ref="tableConsultaBenef"
                  :items="tableDataSolicitudesBeneficio"
                  :busy="isBusyBuscando"
                  :fields="fieldsSolicitudesBeneficio"
                  outlined
                  small
                  responsive
                  show-empty
                  empty-text="No se encontraron resultados para esta búsqueda"
                  @row-dblclicked="dobleClik"
                >
                  <template v-slot:table-busy>
                    <div class="text-center">
                      <b-spinner />
                    </div>
                  </template>
                  <template #cell(usuario)="row">
                    <small>
                      {{ buildNombre(row.item.usuario) }}
                    </small>
                  </template>
                  <template #cell(fechaSolicitud)="row">
                    <small>
                      {{ formatDateTable(row.item.fechaSolicitud, 'DD/MM/YYYY') }}
                    </small>
                  </template>
                  <template #cell(estado)="row">
                    <small v-b-tooltip.hover="detalleFechaEstado(row.item)">
                      {{ row.item.solicitudEstado.nombreCustom }}
                    </small>
                  </template>
                  <template #cell(actions)="row">
                    <div
                      class="btn-group"
                      role="group"
                      aria-label="Basic example"
                    >
                      <!--v-b-tooltip.hover="'Ver detalle'"-->
                      <b-button
                        v-if="usarDetalleIntegrado"
                        size="sm"
                        @click="row.toggleDetails"
                      >
                        <feather-icon icon="EyeIcon" />
                        <!--{{ row.detailsShowing ? 'Ocultar' : 'Mostrar' }} Detalle-->
                      </b-button>
                      <b-button
                        v-if="showBtnCancelarSolicitud(row.item)"
                        v-b-tooltip.hover.right="'Cancelar solicitud'"
                        size="sm"
                        @click="cancelarSolicitud(row)"
                      >
                        <font-awesome-icon
                          v-if="!row.item.isDoing.cancelandoSolicitud"
                          icon="fa-solid fa-xmark"
                        />
                        <span
                          v-if="row.item.isDoing.cancelandoSolicitud"
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        />
                      </b-button>
                      <b-button
                        v-if="showBtnRegistrarEntrega(row.item)"
                        v-b-tooltip.hover.right="'Registrar entrega'"
                        size="sm"
                        @click="entregarSolicitud(row)"
                      >
                        <font-awesome-icon
                          v-if="!row.item.isDoing.entregandoSolicitud"
                          icon="fa-solid fa-gift"
                        />
                        <span
                          v-if="row.item.isDoing.entregandoSolicitud"
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        />
                      </b-button>
                      <!--@click="getPdf()"-->
                      <b-button
                        v-if="showBtnImprimirComprobante(row.item)"
                        title="Imprimir comprobante"
                        size="sm"
                        @click="imprimirComprobante(row)"
                      >
                        <font-awesome-icon
                          v-if="!row.item.isDoing.generandoComprobante"
                          icon="fa-solid fa-print"
                        />
                        <span
                          v-if="row.item.isDoing.generandoComprobante"
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        />
                      </b-button>
                    </div>
                  </template>
                  <template #row-details="row">
                    <detalle-solicitud
                      v-if="isTipoConProducto(row.item.beneficio.beneficioTipo.codigo) || isTipoConProductoPerpetuo(row.item.beneficio.beneficioTipo.codigo) ||
                        isTipoConProductoPaniales(row.item.beneficio.beneficioTipo.codigo)"
                      :item-index="row.index"
                      :detalle-solicitud="row.item"
                      @changeItemIndex="changeItemIndex"
                    />
                    <detalle-solicitud-quincho
                      v-if="isTipoQuincho(row.item.beneficio.beneficioTipo.codigo)"
                      :item-index="row.index"
                      :detalle-solicitud="row.item"
                      @changeItemIndex="changeItemIndex"
                    />
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </div>
          <b-row>
            <div class="align-self-end ml-auto">
              <b-col md="12">
                <b-input-group>

                  <b-input-group-append>
                    <!--v-b-tooltip.hover="'Seleccione la cantidad de resultados por página'"-->
                    <b-form-select
                      v-model="pageBeneficio.size"
                      :clearable="false"
                      title="Seleccione la cantidad de resultados por página"
                      label="title"
                      :options="optionsPaginationSize"
                      size="sm"
                      :disabled="isBusyBuscando"
                      @change="validationFormUsuario()"
                    />
                  </b-input-group-append>

                  <b-pagination
                    id="pgnbENEFICIO"
                    v-model="currentPage"
                    :total-rows="pageTotalElements"
                    :per-page="pageSize"
                    :disabled="isBusyBuscando"
                    @change="changePaginate"
                  />
                </b-input-group>
              </b-col>
            </div>
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
// import BCardCode from '@core/components/b-card-code'
import { mapGetters } from 'vuex'
import { required, email } from '@validations'
import { consultaSolicitudResource } from '@/services/consultaSolicitudResource'
import { solicitudResource } from '@/services/solicitudResource'
import { solicitudEstadoResource } from '@/services/solicitudEstadoResource'
import { reporteResource } from '@/services/reporteResource'
import { saveAs } from 'file-saver'
import useAppConfig from '@core/app-config/useAppConfig'
import {
  BENE_TIPO_CON_PRODUCTO,
  BENE_TIPO_CON_PRODUCTO_PERPETUO,
  BENE_TIPO_QUINCHO,
  BENE_TIPO_CON_PRODUCTO_PANIALES,
} from '@/utils/codigoTipoBeneConst'
import DetalleSolicitud from './DetalleSolicitud.vue'
import DetalleSolicitudQuincho from './DetalleSolicitudQuincho.vue'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
  components: {
    // BCardCode,
    DetalleSolicitud,
    DetalleSolicitudQuincho,
  },
  data() {
    return {
      useLocalStorageLastSearch: true,
      usarDetalleIntegrado: true,
      cargando: false,
      required,
      email,
      pageTotalElements: 0,
      currentPage: 1,
      pageSize: 0,
      filtro: null,
      pageBeneficio: {
        page: 0,
        size: 7,
      },
      swallConfig: {
        background: 'linear-gradient(325deg, #000000 0%, #2a90cb 100%)',
        textColor: 'white',
      },
      BENE_TIPO_CON_PRODUCTO,
      BENE_TIPO_CON_PRODUCTO_PERPETUO,
      BENE_TIPO_QUINCHO,
      BENE_TIPO_CON_PRODUCTO_PANIALES,
      consultaBeneSolicitudFiltroDto: {
        usuarioDelegacionId: null,
        solicitanteCuil: null,
        beneficioId: null,
        solicitudEstadoId: null,
        fechaDesdeComponent: null,
        fechaDesde: null,
        fechaHastaComponent: null,
        feachaHasta: null,
        pageNumber: null,
        pageSize: null,
        sort: null,
        order: null,
      },
      nameModulo: 'CONSULTA_BENEFICIO',
      isBusyBuscando: false,
      isBusyBuscandoEstados: false,
      isBusyBuscandoListaBeneficios: false,
      isBusyReporteExcel: false,
      isActivando: false,
      tableDataSolicitudesBeneficio: [],
      solicitudEstadoDto: [],
      optionsPaginationSize: [5, 7, 10, 15, 20, 25, 30],
      optionsEstado: [
        { id: null, nombre: 'Todos' },
      ],
      optionsBeneficio: [
        { id: null, nombre: 'Todos' },
      ],
      fieldsSolicitudesBeneficio: [
        {
          key: 'id', label: 'id',
        },
        {
          key: 'solicitanteCuil', label: 'C.U.I.L.',
        },
        {
          key: 'usuario', label: 'afiliado',
        },
        {
          key: 'beneficio.nombre', label: 'beneficio',
        },
        {
          key: 'beneficiarioNombre', label: 'beneficiario',
        },
        {
          key: 'fechaSolicitud', label: 'fecha solicitud',
        },
        {
          key: 'estado', label: 'estado',
        },
        { key: 'actions', label: 'Acciones' },
      ],
    }
  },

  computed: {
    showBtnExportarExcelConsutaSolicitud() {
      return this.contieneGestor
    },
    contieneEntregador() {
      let contieneEntregador = false
      const someEntregador = this.currentUser.roles.filter(rol => rol.codigo === 'ENTREGADOR_BENEFICIOS')
      if (someEntregador.length > 0) {
        contieneEntregador = true
      }
      return contieneEntregador
    },
    contieneAfiliado() {
      let contieneAfiliado = false
      const someAfiliado = this.currentUser.roles.filter(rol => rol.codigo === 'AFILIADO')
      if (someAfiliado.length > 0) {
        contieneAfiliado = true
      }
      return contieneAfiliado
    },
    contieneGestor() {
      let contieneEntregador = false
      const someGestor = this.currentUser.roles.filter(rol => rol.codigo === 'GESTOR_BENEFICIOS')
      if (someGestor.length > 0) {
        contieneEntregador = true
      }
      return contieneEntregador
    },
    isGestor() {
      let isGestor = false
      const someGestor = this.currentUser.roles.filter(rol => rol.codigo === 'GESTOR_BENEFICIOS')
      if (someGestor.length > 0) {
        isGestor = true
      }
      return isGestor
    },
    isEntregadorButNotGestor() {
      let isEntregadorButNotGestor = false
      const someEntregador = this.currentUser.roles.filter(rol => rol.codigo === 'ENTREGADOR_BENEFICIOS')
      const someGestor = this.currentUser.roles.filter(rol => rol.codigo === 'GESTOR_BENEFICIOS')
      if (someEntregador.length > 0 && someGestor.length === 0) {
        // Si tiene el rol afiliado y este es el unico rol presente en el array Roles, entonces es AFILIADO unicamente.
        isEntregadorButNotGestor = true
      }
      return isEntregadorButNotGestor
    },
    isAfiliadoOnly() {
      let afiliadoOnly = false
      const items = this.currentUser.roles.filter(rol => rol.codigo === 'AFILIADO')
      if (this.currentUser.roles.length === 1 && items.length > 0) {
        // Si tiene el rol afiliado y este es el unico rol presente en el array Roles, entonces es AFILIADO unicamente.
        afiliadoOnly = true
      }
      return afiliadoOnly
    },
    maxDate() {
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      const maxDate = new Date(today)
      return maxDate
    },
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
        currentUser: 'getCurrentUser',
      },
    ),
  },
  created() {
    this.setFechaInicial()
    this.getSolicitudEstadoLista()
    this.getListaBeneficiosSelect()
    this.getSolicitudesFilter()
  },
  methods: {
    isTipoConProducto(codigo) {
      if (codigo === BENE_TIPO_CON_PRODUCTO) {
        return true
      }
      return false
    },
    isTipoConProductoPerpetuo(codigo) {
      if (codigo === BENE_TIPO_CON_PRODUCTO_PERPETUO) {
        return true
      }
      return false
    },
    isTipoConProductoPaniales(codigo) {
      if (codigo === BENE_TIPO_CON_PRODUCTO_PANIALES) {
        return true
      }
      return false
    },
    isTipoQuincho(codigo) {
      if (codigo === BENE_TIPO_QUINCHO) {
        return true
      }
      return false
    },
    setSwallColor() {
      const { skin } = useAppConfig()
      if (skin.value === 'dark') {
        // this.swallConfig.background = 'linear-gradient(325deg, #000000 0%, #2a90cb 100%)'
        this.swallConfig.background = '#1A2F50'

        this.swallConfig.textColor = 'white'
      } else {
        // this.swallConfig.background = 'linear-gradient(325deg, #ffffff 0%, #ffffff 100%)'
        this.swallConfig.background = '#FFFFFF)'
        this.swallConfig.textColor = 'black'
      }
    },
    // eslint-disable-next-line no-unused-vars
    dobleClik(record, index, mouseEvent) {
      this.tableDataSolicitudesBeneficio.forEach(item => {
        const objIndex = this.tableDataSolicitudesBeneficio.findIndex((obj => obj.id === item.id))
        if (objIndex !== index) {
          // eslint-disable-next-line no-underscore-dangle, no-param-reassign
          item._showDetails = false
        }
      })
      // eslint-disable-next-line no-underscore-dangle
      this.tableDataSolicitudesBeneficio[index]._showDetails = !this.tableDataSolicitudesBeneficio[index]._showDetails
    },
    showBtnImprimirComprobante(item) {
      const cumpleCondicionEstado = item.solicitudEstado.nombre === 'ENTREGADA'
      return cumpleCondicionEstado && this.contieneEntregador
    },
    showBtnRegistrarEntrega(item) {
      const cumpleCondicionEstado = item.solicitudEstado.nombre === 'APROBADA'
      return cumpleCondicionEstado && this.contieneEntregador
    },
    showBtnCancelarSolicitud(item) {
      const cumpleCondicionEstadoPendiente = item.solicitudEstado.nombre === 'PENDIENTE'
      const cumpleCondicionEstadoAprobada = item.solicitudEstado.nombre === 'APROBADA'
      const condGestorYAprobada = this.contieneGestor && cumpleCondicionEstadoAprobada
      const condAfiliadYPendiente = this.contieneAfiliado && cumpleCondicionEstadoPendiente
      const condSolicitudPropia = (this.currentUser.cuil === item.solicitanteCuil)
      const condicion = (condGestorYAprobada) || (condAfiliadYPendiente && condSolicitudPropia)
      return condicion
    },
    changeItemIndex({ detalleSolicitud }) {
      const objIndex = this.tableDataSolicitudesBeneficio.findIndex((obj => obj.id === detalleSolicitud.id))
      if (objIndex !== -1) {
        this.tableDataSolicitudesBeneficio[objIndex].solicitudEstado = { ...detalleSolicitud.solicitudEstado }
        this.tableDataSolicitudesBeneficio[objIndex].motivoRechazo = detalleSolicitud.motivoRechazo
      } else {
        console.error('Objeto ,objIndex, no encontrado', objIndex)
      }
    },
    onFechaDesdeChange() {
      if (this.consultaBeneSolicitudFiltroDto.fechaDesdeComponent > this.consultaBeneSolicitudFiltroDto.fechaHastaComponent) {
        this.consultaBeneSolicitudFiltroDto.fechaHastaComponent = this.consultaBeneSolicitudFiltroDto.fechaDesdeComponent
      }
    },
    setFechaInicial() {
      this.consultaBeneSolicitudFiltroDto.fechaDesdeComponent = this.maxDate
      this.consultaBeneSolicitudFiltroDto.fechaHastaComponent = this.maxDate
    },
    detalleFechaEstado(item) {
      let detalle = ''
      if (item.solicitudEstado.codigo === '005') {
        // 005 = entregada
        detalle += this.formatDateTable(item.fechaEntregado, 'DD/MM/YYYY')
      }
      return detalle
    },
    buildNombre(item) {
      return `${item.apellido}, ${item.nombre}`
    },
    onSubmit() {
      if (!this.isBusyBuscando) {
        this.validationFormUsuario()
      }
    },
    async entregarSolicitud(row) {
      this.solicitudEstadoDto = row.item.solicitudEstado
      // this.showMessageBig('Entrega Solicitudes', 'info', `No implementado <hr> Estado actual: ${row.item.solicitudEstado.nombre}`)
      const idSolicitud = row.item.id
      const indexTable = row.index
      const objIndex = this.tableDataSolicitudesBeneficio.findIndex((obj => obj.id === idSolicitud))
      const res = await this.preguntarSiNo('Entrega de beneficio', 'question', '¿Confirma la entrega del beneficio?')
      if (res) {
        // 004 CANCELADA
        this.tableDataSolicitudesBeneficio[indexTable].isDoing.entregandoSolicitud = true
        consultaSolicitudResource().generarEntregaSolicitud(idSolicitud, this.solicitudEstadoDto).then(result => {
          if (objIndex !== -1) {
            this.tableDataSolicitudesBeneficio[objIndex].solicitudEstado = { ...result.solicitudEstado }
            this.tableDataSolicitudesBeneficio[objIndex].fechaEntregado = result.fechaEntregado
            this.getComprobante(idSolicitud)
          } else {
            console.error('Objeto ,objIndex, no encontrado', objIndex)
          }
        }).catch(error => {
          console.error(error)
        })
          .finally(() => {
            this.tableDataSolicitudesBeneficio[objIndex].isDoing.entregandoSolicitud = false
          })
      }
    },
    imprimirComprobante(row) {
      this.tableDataSolicitudesBeneficio[row.index].isDoing.generandoComprobante = true
      this.getComprobante(row.item.id).then(() => {
        this.tableDataSolicitudesBeneficio[row.index].isDoing.generandoComprobante = false
      })
    },
    getComprobante(idSolicitud) {
      this.isBusyReporte = true
      return reporteResource()
        .getComprobante(idSolicitud)
        .then(resultsReporte => {
          const date = new Date()
          const fileDate = this.formatDateTime(date, 'DD_MM_YYYY_HH_mm_ssZZ', 'DD-MM-YYYY-HH_mm_ssZZ')
          const blob = new Blob([resultsReporte], { type: 'application/pdf;charset=utf-8' })
          saveAs(blob, `comprobante_${fileDate}.PDF`)
        }).catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.isBusyReporte = false
        })
    },
    async cancelarSolicitud(row) {
      const idSolicitud = row.item.id
      const indexTable = row.index
      this.solicitudEstadoDto = row.item.solicitudEstado

      const res = await this.preguntarSiNo('Cancelación', 'question', '¿Confirma la cancelación de la solicitud?')
      if (res) {
        // 004 CANCELADA
        this.tableDataSolicitudesBeneficio[indexTable].isDoing.cancelandoSolicitud = true
        this.updateEstado(idSolicitud, '004', indexTable)
      }
    },
    preguntarSiNo(titleMessage, icon_, message) {
      this.setSwallColor()
      const htmlText = `<p style="color:${this.swallConfig.textColor}">${message}</p>`
      return this.$swal({
        allowOutsideClick: false,
        title: titleMessage,
        // text: `¿Desea desactivar el usuario ${item.apellido} ${item.nombre}?`,
        html: htmlText,
        icon: icon_,
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        background: this.swallConfig.background,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger',
        },
        buttonsStyling: true,
      }).then(result => {
        if (result.value) {
          return true
        }
        return false
      })
    },
    updateEstado(idSolicitud, codigoEstado) {
      const objIndex = this.tableDataSolicitudesBeneficio.findIndex((obj => obj.id === idSolicitud))
      consultaSolicitudResource().updateSolicitudEstado(idSolicitud, codigoEstado, this.solicitudEstadoDto).then(result => {
        if (objIndex !== -1) {
          this.tableDataSolicitudesBeneficio[objIndex].solicitudEstado = { ...result.solicitudEstado }
        } else {
          console.error('Objeto ,objIndex, no encontrado', objIndex)
        }
      }).catch(error => {
        console.error('error', error)
      })
        .finally(() => {
          this.tableDataSolicitudesBeneficio[objIndex].isDoing.cancelandoSolicitud = false
        })
    },
    clearSearchConsultaSolicitudes() {
      if (!this.isAfiliadoOnly) {
        // Si es solo afiliado, no liempiamos el campo ya que siempre se debe incluir como filtro
        // porque solo puede ver sus propias solicitudes y ninguna otra.
        this.consultaBeneSolicitudFiltroDto.solicitanteCuil = null
      }

      this.consultaBeneSolicitudFiltroDto.beneficioId = null
      this.consultaBeneSolicitudFiltroDto.solicitudEstadoId = null
      this.consultaBeneSolicitudFiltroDto.fechaDesdeComponent = this.maxDate
      this.consultaBeneSolicitudFiltroDto.fechaDesde = null
      this.consultaBeneSolicitudFiltroDto.fechaHastaComponent = this.maxDate
      this.consultaBeneSolicitudFiltroDto.feachaHasta = null
      this.consultaBeneSolicitudFiltroDto.solicitanteCuil = null
      this.consultaBeneSolicitudFiltroDto.solicitanteCuil = null
      this.consultarSolicitudes()
    },
    consultarSolicitudes() {
      this.currentPage = 1
      this.pageBeneficio.page = 0
      this.getSolicitudesFilter()
    },
    getSolicitudEstadoLista() {
      this.isBusyBuscandoEstados = true
      solicitudEstadoResource().getAllSolicitudEstado().then(result => {
        this.optionsEstado = this.optionsEstado.concat(result)
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoEstados = false
        })
    },
    getListaBeneficiosSelect() {
      if (this.isAfiliadoOnly) {
        this.listaSelectFromSolicitudes()
      } else {
        this.listaSelectFromBeneficios()
      }
    },
    listaSelectFromBeneficios() {
      this.isBusyBuscandoListaBeneficios = true
      solicitudResource().getListaBeneficiosSelect().then(results => {
        this.optionsBeneficio = this.optionsBeneficio.concat(results)
      }).catch(error => {
        console.error('error', error)
      })
        .finally(() => {
          this.isBusyBuscandoListaBeneficios = false
        })
    },
    listaSelectFromSolicitudes() {
      this.isBusyBuscandoListaBeneficios = true
      solicitudResource().getListaBeneficiosSelectFromSolicitudes(this.currentUser.cuil).then(results => {
        this.optionsBeneficio = this.optionsBeneficio.concat(results)
      }).catch(error => {
        console.error('error', error)
      })
        .finally(() => {
          this.isBusyBuscandoListaBeneficios = false
        })
    },
    getSolicitudesFilter() {
      this.isBusyBuscando = true
      this.tableDataSolicitudesBeneficio = []
      this.consultaBeneSolicitudFiltroDto.pageNumber = this.pageBeneficio.page
      this.consultaBeneSolicitudFiltroDto.pageSize = this.pageBeneficio.size
      // this.consultaBeneSolicitudFiltroDto.sort = 'beneficiarioNombre'
      this.consultaBeneSolicitudFiltroDto.sort = 'solicitanteCuil'
      this.consultaBeneSolicitudFiltroDto.order = 'asc'
      this.consultaBeneSolicitudFiltroDto.fechaDesde = this.formatDateToJavaT(this.consultaBeneSolicitudFiltroDto.fechaDesdeComponent, 'YYYY-MM-DD', 'DD-MM-YYYY HH:mm')
      this.consultaBeneSolicitudFiltroDto.fechaHasta = this.formatDateToJavaT(this.consultaBeneSolicitudFiltroDto.fechaHastaComponent, 'YYYY-MM-DD', 'DD-MM-YYYY HH:mm')
      if (this.isEntregadorButNotGestor) {
        this.consultaBeneSolicitudFiltroDto.usuarioDelegacionId = this.currentUser.delegacion.id
      }
      if (this.isAfiliadoOnly) {
        this.consultaBeneSolicitudFiltroDto.solicitanteCuil = this.currentUser.cuil
      }
      consultaSolicitudResource().findSolicitudesFilter(this.consultaBeneSolicitudFiltroDto)
        .then(resultFindSolicitudesFilter => {
          this.tableDataSolicitudesBeneficio = resultFindSolicitudesFilter.content
          this.pageTotalElements = resultFindSolicitudesFilter.totalElements
          this.pageNumber = resultFindSolicitudesFilter.number
          this.pageSize = resultFindSolicitudesFilter.size
        }).catch(error => {
          console.error('errors:', error)
          this.tableDataSolicitudesBeneficio = []
        })
        .finally(() => {
          this.isBusyBuscando = false
        })
    },
    downloadReporteExcel() {
      this.isBusyReporteExcel = true
      return reporteResource()
        .getReporteExcelConsultaSolicitudes(this.consultaBeneSolicitudFiltroDto)
        .then(resultExcelReport => {
          const date = new Date()
          const fileDate = this.formatDateTime(date, 'DD_MM_YYYY_HH_mm_ssZZ', 'DD-MM-YYYY-HH_mm_ssZZ')
          // const blob = new Blob([resultExcelReport], { type: 'application/octet-stream;charset=utf-8' })
          const blob = new Blob([resultExcelReport], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          saveAs(blob, `Consulta_${fileDate}.xlsx`)
        }).catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.isBusyReporteExcel = false
        })
    },
    changePaginate(item) {
      this.pageBeneficio.page = item - 1
      this.isBusyBuscando = true
      this.getSolicitudesFilter()
    },
    showMessageBig(titleMessage, icon_, htmlErrors) {
      this.$swal({
        title: titleMessage,
        // text: message,
        html: htmlErrors,
        icon: icon_,
        // showCancelButton: true,
        confirmButtonText: 'Cerrar',
        // cancelButtonText: 'No',

        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          //
        }
      })
    },
    validationFormUsuario() {
      this.$refs.formValidationConsultaBeneficio.validate().then(success => {
        if (success) {
          this.consultarSolicitudes()
        }
      })
    },
  },
}
</script>
<style>
.mycontainer {
  text-align: center;
  padding-top: 28px;
}
</style>
